<template>   
    <AmbitoFlip />  
</template>

<script>
  import AmbitoFlip from '../components/ambito-flip.vue'
  
  

  export default {
    name: 'App',
    components: {
      AmbitoFlip,
      
      
    },
  }
  
</script>